import React from 'react';
function NFTContainer({ nftData, getRankClass, hideImages }) {
  return (
    <div id="nft-container" className="main-content">
      <div className="nft-grid">
        {nftData.map((nft, index) => (
          <div key={index} className="nft nft-style">
            <div className={`rank ${getRankClass(nft.rank)}`}>Rank: {nft.rank}</div>
            {!hideImages && <img src={nft.image} alt={nft.name} className="nft-image-style" />} {/* Conditionally render image */}
            <h4>ID: {nft.JsonTokenID}</h4>
            <h4>Name: {nft.name}</h4>
          </div>
        ))}
      </div>
    </div>
  );
}
export default NFTContainer;