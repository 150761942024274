import React, { useEffect, useState } from 'react';
import { useWallet, WalletConnectButton } from '@sei-js/react';
import { checkNFTOwnership } from './App';

function formatWalletAddress(address) {
  if (!address) return '';
  const firstSix = address.slice(0, 6);
  const lastFive = address.slice(-5);
  return `${firstSix}....${lastFive}`;
}

function Home({ handleOwnershipChange, handleCollectionChange, setNftData }) {
  const { connectedWallet, accounts, disconnect } = useWallet();
  const [userOwnsNFT, setUserOwnsNFT] = useState(false);
  const walletAddress = connectedWallet && accounts.length > 0 ? accounts[0].address : null;

  useEffect(() => {
    console.log('connectedWallet:', connectedWallet);
    console.log('Home - useEffect - walletAddress:', walletAddress);
    if (walletAddress) {
      checkNFTOwnership(walletAddress, (isOwner) => {
        setUserOwnsNFT(isOwner);
        handleOwnershipChange(isOwner);
      });
    }
  }, [connectedWallet, walletAddress, handleOwnershipChange]);


  const handleDisconnect = () => {
    disconnect();
    handleOwnershipChange(false);
    handleCollectionChange({ target: { value: '' } });
    setNftData([]);
  };

  return (
    <div className="walletConnectButtonContainer">
      {!connectedWallet ? (
        <WalletConnectButton />
      ) : (
        <div id="welcome-wallet-container">
          {/* Conditional rendering based on userOwnsNFT and formatted wallet address */}
          {userOwnsNFT ? (
            <p>Welcome Explorer!<br />Connected Wallet: {formatWalletAddress(walletAddress)}</p>
          ) : (
            <p>Connected Wallet: {formatWalletAddress(walletAddress)}<br />Tool only available to <a href="https://pallet.exchange/collection/seifari-explorers">Explorers</a></p>
          )}
          <button onClick={handleDisconnect}>Disconnect</button> {/* Disconnect button */}
        </div>
      )}
    </div>
  );
}

export default Home;