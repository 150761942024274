import React, { useState, useEffect } from 'react';
import { collectionNames } from './collections';
import { useWallet, WalletConnectButton } from '@sei-js/react';

function Sidebar({
  fetchTop100,
  handleCollectionChange,
  handleSortByTokenIdClick,
  handleSortByRankClick,
  handleResetDisplayClick: parentResetDisplay,
  handleSearchBoxInput,
  handleSearchRankTokenInput,
  traits,
  fetchEntireCollection,
  displayNFTs,
  selectedCollection,
  userOwnsNFT,
  handleOwnershipChange,
  hideImages,
  toggleImages
}) {
  const [collections] = useState(collectionNames);
  const [startingNumber, setStartingNumber] = useState('');
  const [displayCount, setDisplayCount] = useState('');
  const [traitOptions, setTraitOptions] = useState([]);
  const { connectedWallet } = useWallet();

 const [isNFTHolder, setIsNFTHolder] = useState(false);

  useEffect(() => {
    setIsNFTHolder(userOwnsNFT);
  }, [userOwnsNFT]);

  const handleDisconnect = () => {
    setIsNFTHolder(false);
  };
  
  const handleDisconnectClick = () => {
  handleDisconnect();
};

  const handleInputChange = (name, value) => {
    if (name === 'startingNumber') setStartingNumber(value);
    if (name === 'displayCount') setDisplayCount(value);
  };

  const handleLocalResetDisplayClick = () => {
    setStartingNumber('');
    setDisplayCount('');
    document.getElementById('search-rank-token').value = '';
    document.getElementById('search-box').value = '';
    if (parentResetDisplay) parentResetDisplay();
    window.scrollTo(0, 0);
  };

  const handleSearchMintMode = () => {
    if (startingNumber !== '' && displayCount !== '') {
      const start = parseInt(startingNumber);
      const count = parseInt(displayCount);
      fetchEntireCollection(selectedCollection)
        .then(data => {
          const filteredData = data.filter(nft => {
            const tokenId = parseInt(nft.JsonTokenID);
            return tokenId >= start && tokenId < start + count;
          });
          filteredData.sort((a, b) => parseInt(a.JsonTokenID) - parseInt(b.JsonTokenID));
          displayNFTs(filteredData);
        })
        .catch(error => console.error('Error fetching or filtering NFT data:', error));
    }
  };

  const handleTraitChange = (event) => {
    const selectedTrait = event.target.value;
    const options = Object.keys(traits[selectedTrait]).map(value => ({
      value,
      label: `${value} (${traits[selectedTrait][value]})`
    }));
    setTraitOptions(options);
    document.getElementById('value-selector').style.display = 'block';
  };

  const handleValueChange = (event) => {
    const selectedTrait = document.getElementById('trait-selector').value;
    const selectedValue = event.target.value;
    fetchEntireCollection(selectedCollection)
      .then(data => {
        const filteredData = data.filter(nft =>
          nft.attributes.some(attr =>
            attr.trait_type === selectedTrait && attr.value === selectedValue
          )
        );
        displayNFTs(filteredData);
      })
      .catch(error => console.error('Error fetching or filtering NFT data:', error));
  };

   return (
    <div className="sidebar">
      {/* Render collection selector if the user owns an NFT */}
      {isNFTHolder && (
<select id="collection-selector" onChange={handleCollectionChange}>
  <option value="">Select Collection</option>
  {collections.map(collection => (
    <option key={collection} value={collection}>{collection}</option>
  ))}
</select>
      )}
	  
	  <button onClick={toggleImages}>
  {hideImages ? 'Show Images' : 'Hide Images'}
</button>
          <div className="mint-mode">
            <h2>Mint Mode</h2>
            <input
              type="number"
              value={startingNumber}
              onChange={(e) => handleInputChange('startingNumber', e.target.value)}
              placeholder="Starting Number"
              min="0"
            />
            <input
              type="number"
              value={displayCount}
              onChange={(e) => handleInputChange('displayCount', e.target.value)}
              placeholder="How many to display"
              min="1"
            />
            <button id="search-mint-mode" onClick={handleSearchMintMode}>Search</button>
          </div>
          <div className="filtering-options" id="filtering-options">
            <button id="display-top-100" onClick={fetchTop100}>Display Top 100</button>
            <button id="sort-by-token-id" onClick={handleSortByTokenIdClick}>Sort All by Token ID</button>
            <button id="sort-by-rank" onClick={handleSortByRankClick}>Sort All by Rank</button>
            <input
              type="text"
              id="search-box"
              placeholder="Search Token ID or Trait"
              onChange={handleSearchBoxInput}
            />
            <input
              type="text"
              id="search-rank-token"
              placeholder="Search By Rank"
              onChange={handleSearchRankTokenInput}
            />
            <select id="trait-selector" onChange={handleTraitChange}>
              <option>Select Trait</option>
              {Object.keys(traits).map(trait => (
                <option key={trait} value={trait}>{trait}</option>
              ))}
            </select>
            <select id="value-selector" onChange={handleValueChange} style={{ display: 'none' }}>
              <option>Select Value</option>
              {traitOptions.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
              ))}
            </select>
            <button onClick={handleLocalResetDisplayClick}>Reset Display</button>
          </div>
    </div>
  );
}

export default Sidebar;