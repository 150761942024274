import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import NFTContainer from './NFTContainer';
import { SeiWalletProvider, useWallet } from '@sei-js/react';
import Home from './Home';
import { CosmWasmClient } from '@cosmjs/cosmwasm-stargate';
import { CONTRACT_ADDRESS } from './constants';
export const checkNFTOwnership = async (userWalletAddress, setUserOwnsNFT) => {
  console.log('Checking NFT ownership...');
  try {
    const client = await CosmWasmClient.connect('https://rpc.sei-apis.com');
    console.log(`Querying contract: ${CONTRACT_ADDRESS} for owner: ${userWalletAddress}`);
    const queryResponse = await client.queryContractSmart(CONTRACT_ADDRESS, {
      tokens: { owner: userWalletAddress },
    });
    console.log('Query response:', queryResponse);
    setUserOwnsNFT(queryResponse.tokens && queryResponse.tokens.length > 0);
    console.log('Ownership status:', queryResponse.tokens && queryResponse.tokens.length > 0);
  } catch (error) {
    console.error('Error checking NFT ownership:', error);
  }
};

function App() {
  const [nftData, setNftData] = useState([]);
  const [traits, setTraits] = useState({});
  const [selectedCollection, setSelectedCollection] = useState('Seifari Explorers');
  const [traitOptions, setTraitOptions] = useState([]);
  const { connected, address: userWalletAddress, disconnect } = useWallet(); 
  const [userOwnsNFT, setUserOwnsNFT] = useState(false);
  const [nftHolders, setNftHolders] = useState([]);
  const [isNftHolder, setIsNftHolder] = useState(false);
  const rpcEndpoint = 'https://sei-m.rpc.n0ok.net/';
  
  useEffect(() => {
    preloadNftHolders();
  }, []);

  useEffect(() => {
    if (connected) {
      checkNFTOwnership(userWalletAddress, setUserOwnsNFT);
    }
  }, [connected, userWalletAddress]);

  useEffect(() => {
    if (connected && userOwnsNFT) {
      fetchEntireCollection(selectedCollection);
    }
  }, [connected, userOwnsNFT, selectedCollection]);

  useEffect(() => {
    console.log('Preloading NFT holders...');
    console.log('NFT holders preloaded:', nftHolders);
  }, [nftHolders]);

  useEffect(() => {
    async function preloadNftHolders() {
      try {
        const client = await CosmWasmClient.connect(rpcEndpoint);
        let owners = new Set();
        const batchSize = 100;
        for (let tokenId = 1; tokenId <= 233; tokenId += batchSize) {
          const promises = [];
          for (let id = tokenId; id < tokenId + batchSize && id <= 233; id++) {
            promises.push(
              client.queryContractSmart(CONTRACT_ADDRESS, { owner_of: { token_id: id.toString() } })
                .then(response => {
                  if (response.owner) {
                    owners.add(response.owner);
                  }
                })
                .catch(error => {
                  console.error(`Error querying owner for token ID ${id}:`, error);
                })
            );
          }
          await Promise.allSettled(promises);
          console.log(`Finished querying batch up to token ID ${Math.min(tokenId + batchSize - 1, 233)}`);
        }
        console.log('NFT holders retrieved:', Array.from(owners));
        setNftHolders(Array.from(owners));
      } catch (error) {
        console.error('Error querying NFT holders:', error);
      }
    }
  
    preloadNftHolders();
  }, []);

  const getRankClass = (rank) => {
    if (rank <= 25) return 'yellow';
    if (rank <= 100) return 'purple';
    if (rank <= 300) return 'blue';
    if (rank <= 600) return 'green';
    return 'grey';
  };
  
const [hideImages, setHideImages] = useState(false);

const toggleImages = () => {
  console.log('toggleImages function called');
  setHideImages(!hideImages);
  console.log('hideImages toggled to:', !hideImages);
};

  const displayNFTs = (nfts) => {
    console.log('Displaying NFTs:', nfts);
    setNftData(nfts);
  };

const fetchData = (collectionId) => {
  console.log(`Fetching data for collection: ${collectionId}`);
  fetch(`/jsondata/${collectionId}.json`)
    .then(response => response.json())
    .then(data => {
        console.log(`Data fetched for collection: ${collectionId}`, data);
        setNftData(data.slice(0, 25));
        parseTraits(data);
    })
    .catch(error => console.error('Error fetching NFT data:', error));
};

  const fetchTop100 = () => {
    console.log('Fetching entire collection and sorting top 100...');
    window.scrollTo(0, 0); 
    fetchEntireCollection(selectedCollection)
      .then(data => {
        const sortedData = [...data].sort((a, b) => a.rank - b.rank); 
        const top100 = sortedData.slice(0, 100);
        displayNFTs(top100); 
      })
      .catch(error => console.error('Error fetching or sorting top 100 NFT data:', error));
  };

const preloadNftHolders = async () => {
  console.log('Starting to query for NFT holders...');
  try {
    const client = await CosmWasmClient.connect(rpcEndpoint);
    let owners = new Set();
    const batchSize = 100;
    for (let tokenId = 1; tokenId <= 233; tokenId += batchSize) {
      const promises = [];
      for (let id = tokenId; id < tokenId + batchSize && id <= 233; id++) {
        promises.push(
          client.queryContractSmart(CONTRACT_ADDRESS, { owner_of: { token_id: id.toString() } })
            .then(response => {
              if (response.owner) {
                owners.add(response.owner);
              }
            })
            .catch(error => {
              console.error(`Error querying owner for token ID ${id}:`, error);
            })
        );
      }
      await Promise.allSettled(promises);
      console.log(`Finished querying batch up to token ID ${Math.min(tokenId + batchSize - 1, 233)}`);
    }
    console.log('NFT holders retrieved:', Array.from(owners));
    setNftHolders(Array.from(owners));
  } catch (error) {
    console.error('Error querying NFT holders:', error);
  }
};

useEffect(() => {
  preloadNftHolders();
}, []);

  const fetchEntireCollection = (collectionId, limit) => {
    console.log(`Fetching entire collection: ${collectionId} with limit: ${limit}`);
    return fetch(`/jsondata/${collectionId}.json`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);
        let collectionData = data;
        if (limit) {
          collectionData = data.slice(0, limit);
        }
        return collectionData; 
      })
      .catch(error => {
        console.error('Error fetching NFT data:', error);
        throw error; 
      });
  };

  const parseTraits = (nfts) => {
    const newTraits = {};
    nfts.forEach(nft => {
      nft.attributes.forEach(attr => {
        if (!newTraits[attr.trait_type]) {
          newTraits[attr.trait_type] = {};
        }
        if (!newTraits[attr.trait_type][attr.value]) {
          newTraits[attr.trait_type][attr.value] = 1;
        } else {
          newTraits[attr.trait_type][attr.value]++;
        }
      });
    });
    console.log('Parsed Traits:', newTraits); 
    setTraits(newTraits); 
  };

  const populateTraitDropdown = () => {
    const options = Object.keys(traits).map(trait => (
      <option key={trait} value={trait}>{trait}</option>
    ));
    setTraitOptions(options);
  };
  
  const handleOwnershipChange = (ownsNFT) => {
    setUserOwnsNFT(ownsNFT);
  };

  const handleCollectionChange = (event) => {
    const collectionId = event.target.value;
    console.log('Collection changed:', collectionId);
    setSelectedCollection(collectionId);
    fetchData(collectionId);
  };

  const handleTraitChange = (event) => {
    const selectedTrait = event.target.value;
    populateValueDropdown(selectedTrait);
  };

  const populateValueDropdown = (trait) => {
    const valueSelector = document.getElementById('value-selector');
    valueSelector.innerHTML = '<option>Select Value</option>';
    Object.keys(traits[trait]).forEach(value => {
      const option = document.createElement('option');
      option.value = value;
      option.textContent = `${value} (${traits[trait][value]})`;
      valueSelector.appendChild(option);
    });
    valueSelector.style.display = 'block';
  };

  const handleValueChange = (event) => {
    const selectedTrait = document.getElementById('trait-selector').value;
    const selectedValue = event.target.value;
    const filteredData = nftData.filter(nft =>
      nft.attributes.some(attr => attr.trait_type === selectedTrait && attr.value === selectedValue)
    );
    displayNFTs(filteredData);
  };

  const handleResetDisplayClick = () => {
    console.log('Resetting display...');
    window.scrollTo(0, 0); 
    fetchData(selectedCollection);
  };
  
  const handleSortByTokenIdClick = () => {
    console.log('Sorting by Token ID...');
    window.scrollTo(0, 0); 
    fetchEntireCollection(selectedCollection)
      .then(data => {
        const sortedData = [...data].sort((a, b) => {
          const tokenIdA = parseInt(a.JsonTokenID, 10);
          const tokenIdB = parseInt(b.JsonTokenID, 10);
          return tokenIdA - tokenIdB;
        });
        displayNFTs(sortedData);
      })
      .catch(error => console.error('Error fetching or sorting NFT data:', error));
  };

  const handleSortByRankClick = () => {
    console.log('Sorting by Rank...');
    window.scrollTo(0, 0); 
    fetchEntireCollection(selectedCollection)
      .then(data => {
        const sortedData = [...data].sort((a, b) => a.rank - b.rank);
        displayNFTs(sortedData);
      })
      .catch(error => console.error('Error fetching or sorting NFT data:', error));
  };

  const handleSearchBoxInput = (event) => {
    const searchText = event.target.value.toLowerCase().trim();
    window.scrollTo(0, 0);
    fetchEntireCollection(selectedCollection)
      .then(data => {
        const filteredData = data.filter(nft => {
          return nft.JsonTokenID.toString().toLowerCase().includes(searchText) ||
                 nft.attributes.some(attr => 
                   attr.value.toString().toLowerCase().includes(searchText)
                 );
        });
        displayNFTs(filteredData);
      })
      .catch(error => console.error('Error fetching or filtering NFT data:', error));
  };

  const walletAddress = 'YOUR_WALLET_ADDRESS_HERE'; 

const handleDisconnect = () => {
  disconnect();
  handleOwnershipChange(false);
  handleCollectionChange({ target: { value: '' } });
  setNftData([]);
};

  const handleSearchRankTokenInput = (event) => {
    const searchValue = parseInt(event.target.value.trim());

    window.scrollTo(0, 0); 

    fetchEntireCollection(selectedCollection)
      .then(data => {
        console.log('Data received:', data);
        const filteredData = data.filter(nft =>
          nft.rank === searchValue
        );
        console.log('Filtered data:', filteredData);
        displayNFTs(filteredData);
      })
      .catch(error => console.error('Error fetching or filtering NFT data:', error));
  };

  return (
    <div className="App">
      <Header />
      <SeiWalletProvider
        chainConfiguration={{
          chainId: 'pacific-1',
          restUrl: 'https://rest.sei-apis.com/',
          rpcUrl: 'https://rpc.sei-apis.com/'
        }}
        wallets={['compass', 'fin']}
      >
        <Home 
          handleOwnershipChange={handleOwnershipChange}
          handleCollectionChange={handleCollectionChange}
          handleDisconnect={handleDisconnect}
		   setNftData={setNftData}
        />
      </SeiWalletProvider>

      <Sidebar
        fetchTop100={fetchTop100}
        handleCollectionChange={handleCollectionChange}
        handleSortByTokenIdClick={handleSortByTokenIdClick}
        handleSortByRankClick={handleSortByRankClick}
        handleResetDisplayClick={handleResetDisplayClick}
        handleSearchBoxInput={handleSearchBoxInput}
        handleSearchRankTokenInput={handleSearchRankTokenInput}
        traits={traits}
        fetchEntireCollection={fetchEntireCollection}
        displayNFTs={displayNFTs}
        selectedCollection={selectedCollection}
        userOwnsNFT={userOwnsNFT}
        handleDisconnect={handleDisconnect} 
		hideImages={hideImages}
        toggleImages={toggleImages} 
      />

      <NFTContainer nftData={nftData} getRankClass={getRankClass} hideImages={hideImages} />
    </div>
  );
}

export default App;